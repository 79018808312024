import Hero from '../components/Hero'
import AboutSection from "../components/AboutSection";

const Home = () => {
    return (
        <div>
            <Hero
                backGroundClass="bg-img1"
                imgAlt="hero image"
                title="THE FOOD"
                subtitle="IS THE FOUNDATION OF THE CONVERSATION"
            />
            {/* <AboutSection /> */}
        </div>

    )
}

export default Home;
