import Hero from '../components/Hero'
import RecipeTile from '../components/RecipeTile'
import Box from '@mui/material/Box';
import recipeData from '../recipeData.json';
import List from '@mui/material/List';

const Recipes = () => {
    return (
        <div>
            <Hero
                backGroundClass="bg-img4"
                imgAlt="hero image"
                title="RECIPES"
            />
            <Box sx={{ flexGrow: 1 }}>
                <List>
                    {recipeData.map((menu, index) => {
                        return <div alignItems="flex-start" key={index}>
                            <RecipeTile menuData={menu}></RecipeTile>
                        </div>
                    })}
                </List>
            </Box>
        </div>

    )
}

export default Recipes;
