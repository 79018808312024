import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import './App.css';
import Menus from "./pages/Menus";
import Recipes from "./pages/Recipes";


function App() {

  return (
    <div>

      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/menus" component={Menus} />
          <Route exact path="/recipes" component={Recipes} />
          <Route exact path="/recipes/:dish" component={Home} />
          <Route exact path="/contact" component={Home} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </div>

  );
}

export default App;
