import Hero from '../components/Hero'
import MenuTile from '../components/MenuTile'
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import menuData from '../menuData.json';

const Menus = () => {
    return (
        <div>
            <Hero
                backGroundClass="bg-img2"
                imgAlt="hero image"
                title="MENU OPTIONS"
            />
            <Box sx={{ flexGrow: 1 }} className="menuTile">
                <Grid container spacing={4} className="pt-20 flex flex-wrap justify-evenly ml-auto">
                    {menuData.map((menu, index) => {
                        return <Grid key={index} item xs={12} md={3} className="p-0 mr-5 ml-5 mb-20">
                            <MenuTile menuData={menu}></MenuTile>
                        </Grid>
                    })}

                </Grid>
            </Box>
        </div>

    )
}

export default Menus;
