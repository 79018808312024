import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Hero = ({ backGroundClass, imgAlt, title, subtitle }) => {
  return (

    <div className={"hero " + backGroundClass}>
      <h1>
        {title}
      </h1>
      <h2 className="heroSubtext">
        {subtitle}
      </h2>
    </div>
  );
}

export default Hero;