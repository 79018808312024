import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const RecipeTile = ({ menuData }) => {


    return (
        <ListItem alignItems="flex-start" className="m-2.5 bg-white w-auto shadow-md">
            <ListItemAvatar>
                <Avatar alt={menuData.cardMedia.alt} src={menuData.cardMedia.image} />
            </ListItemAvatar>
            <ListItemText
                primary={menuData.title}
                secondary={
                    <React.Fragment>
                        {menuData.subheader}
                    </React.Fragment>
                }
            />
        </ListItem>
    );
}

export default RecipeTile;