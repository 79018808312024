import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";


const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    cursor: "pointer",
    paddingLeft: "15px",
    fontStyle: "italic",
    fontWeight: "bold",
    fontFamily: "Palatino Linotype",
  },
  link: {
    textDecoration: "none",
    color: "47,46,46",
    fontSize: "20px",
    marginLeft: theme.spacing(10),
    "&:hover": {
      color: "grey",
      borderBottom: "1px solid black",
    },
  },
}));


const Navbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar className="bg-gray-100 text-black py-8" position="static" >
      <CssBaseline />
      <Toolbar>
        <Typography variant="h4" className={classes.logo}>
          <Link to="/">
            <span className="text-6xl">C</span>
            <span className="text-3xl">ricchi </span>
            <span className="text-6xl">C</span>
            <span className="text-3xl">ookin</span>
          </Link>
        </Typography>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <Link to="/" className={classes.link}>
              Home
            </Link>
            <Link to="/menus" className={classes.link}>
              Menus
            </Link>
            <Link to="/recipes" className={classes.link}>
              Recipes
            </Link>
            <Link to="/contact" className={classes.link}>
              Contact
            </Link>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;